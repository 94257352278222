import { graphql } from 'gatsby'
import React from 'react'

import animation from '../../assets/blog/15/1.gif'
import mobileImg from '../../assets/blog/15/2.png'
import '../../styles/global.css'
import { ContentMain } from '../../components/common/content-main'
import { ContentSubPage } from '../../components/common/content-sub-page'
import { HeaderTitle } from '../../components/common/header-title'
import { CookiesBox } from '../../components/cookies-box'
import { Footer } from '../../components/footer'
import { Navigation } from '../../components/navigation'
import { SEO } from '../../components/seo'

const BlogPage = () => (
  <>
    <SEO title="Cena, kterou platíme za chaotické nakupování" />
    <div className="flex flex-col h-screen justify-between">
      <Navigation />
      <ContentMain>
        <HeaderTitle title="Cena, kterou platíme za chaotické nakupování" />
        <ContentSubPage>
          <p>
            Je pravda, že nejen chlebem živ je člověk, ale i ten příslovečný
            chléb umí dát jednomu docela zabrat. Zvlášť, pokud se stará o
            materiální potřeby celé rodiny. A nákupní seznamy na papírcích už
            nejsou dostatečným nástrojem v našich čím dál složitějších a
            hektičtějších životech.
          </p>
          <p>
            Nákupy musíme často vmáčknout do prostojů mezi pracovními a
            rodinnými povinnostmi. A tak se stane, že před vyzvednutím dítěte z
            kroužku stojíme třeba v drogerii a marně se snažíme si vzpomenout,
            co jsme to tu vlastně potřebovali. Před oslavou narozenin našich
            blízkých pak zoufale hledáme, kam jsme si to před pár měsíci
            zaznamenali tip na skvělý dárek. Nebo vsázíme na divokou kartu a
            kupujeme to, co <b>možná</b> potřebujeme, případně co má koupit
            partner, ale <b>možná</b> zapomene. Možná.
          </p>
          <p>
            Vinou špatné organizace tak ale roztáčíme kolečka stresu. Ten má za
            následek nejen duševní nepohodu a podrážděnost, ale při delším
            trvání i poruchy paměti, spánku a psychosomatické projevy. Chaotické
            nákupy nás taky paradoxně stojí zbytečně mnoho času a peněz. Návraty
            do obchodu pro položky, které jsme zapomněli, řešení zdvojených
            nákupů, posunování úkolů na další den či týden. To vše představuje
            zbytečnou mentální zátěž. Jde vlastně o zlozvyky, a s těmi je dobré
            jednou provždy skoncovat. Dobré a taky jednoduché. Chce to jen najít
            si vhodný nástroj k organizaci nákupů. Nástroj, který se vám
            přizpůsobí a dokáže jednu položku zařadit do různých skupin či
            úrovní. Jedním z takových nástrojů je aplikace Flagis, která navíc
            umožňuje posílat nákupní úkoly a kontrolovat jejich přijetí i
            plnění. Život přináší mnoho výzev, ale nakupování by nemělo být
            jednou z nich.
          </p>

          <div className="my-5">
            <a href={animation} target="_blank" rel="noreferrer">
              <img
                src={animation}
                alt="Demo showcase"
                className="object-contain"
              />
            </a>
          </div>
          <div className="my-5 flex justify-around">
            <a href={mobileImg} target="_blank" rel="noreferrer">
              <img
                src={mobileImg}
                alt="Demo showcase"
                className="object-contain"
              />
            </a>
          </div>
        </ContentSubPage>
      </ContentMain>
      <Footer />
    </div>
    <CookiesBox />
  </>
)

export default BlogPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
